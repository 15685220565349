
import Stories from "react-insta-stories";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import Story from "./Story";

import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
// import { storieAction } from "../../store/storieSlice";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade } from "swiper/core";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/effect-cube/effect-cube.min.css";

import "./DishStories.css";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import StoriesLoader from "./StoriesLoader";

SwiperCore.use([EffectFade]);
const StoriesLazy = React.lazy(() => import("react-insta-stories"));

const DishStories = (props) => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.chef.categories);
  const catName = useSelector((state) => state.chef.categoryName);

  // const headers = props.dishes.map((item) => item?.name);
  // const dishImg = props.dishes.map((item) => item.stories[0].img);
  // const price = props.dishes.map((item) => item?.price);
  const chefId = props.dishes.map((item) => item?.chefId);

  const [swiper, setSwiper] = useState(null);
  let storiesList = [];

  const categoriesWithClass = categories[chefId[0]]?.filter((category) => {
    if(category.img)
    return {
      ...category,
      ringClass: "ring-" + category.color,
    };
  });

  let storyNum = categoriesWithClass.findIndex(
    (category) => category.name === catName
  );
  console.log(storyNum, categoriesWithClass);
  // const [storyNumber, setStoryNumber] = useState(storyNum);
  let storySlideNumber = 0;

  const [my_swiper, set_my_swiper] = useState({});

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(storyNum)
    }
  }, [swiper])

  const swipeToNextSlide = () => {
    if (swiper) {
      const curInd = swiper.activeIndex;
      if ((curInd === categoriesWithClass.length - 1)) {
        return false;
      } else {
        return true;
      }
    }
  }

  const handlers = useSwipeable({
    onSwipedUp: () => {
      console.log('Swiped Up');
      my_swiper.slideNext();
    }
  })

  const PauseStories = (action, isActive) => {
      useEffect(() => {
        if(!isActive){
          setTimeout(() => {
            action('pause');
          }, 0);
        } else{
          // setTimeout(() => {
          //   action('play');
          // }, 0);
        }
      }, []);
  }

  // const handleLeftSwipe = () => {
  //   try {
  //     console.log("Left Swiped");
  //     let i = categories[chefId[0]].findIndex(
  //       (category) => category.name === catName
  //     );

  //     if (i + 1 < categories[chefId[0]].length) {
  //       if (!categories[chefId[0]][i + 1]?.img) {
  //         i = i + 1;
  //       }

  //       dispatch(storieAction.setSwiped({ swiped: true }));
  //       dispatch(chefActions.onStoryBackClick());

  //       setTimeout(() => {
  //         dispatch(storieAction.setSwiped({ swiped: false }));
  //         dispatch(
  //           chefActions.onStoryClick({
  //             storyId: 0,
  //             storyImg: categories[chefId[0]][i + 1].img
  //               ? S3_MENU_THUMBNAIL_FOLDER + categories[chefId[0]][i + 1]?.img
  //               : "",
  //             dishes: categories[chefId[0]][i + 1].dishes,
  //             categories: categoriesWithClass ? categoriesWithClass : "",
  //             chefId: chefId[0],
  //             categoryName: categories[chefId[0]][i + 1].name,
  //           })
  //         );
  //       }, 800);
  //     } else {
  //       dispatch(chefActions.onStoryBackClick());
  //     }
  //   } catch (error) {
  //     dispatch(chefActions.onStoryBackClick());
  //   }
  // };

  // const handleRightSwipe = () => {
  //   try {
  //     console.log("Right Swiped");
  //     let i = categories[chefId[0]].findIndex(
  //       (category) => category.name === catName
  //     );

  //     if (i > 0) {
  //       if (!categories[chefId[0]][i - 1]?.img) {
  //         i = i - 1;
  //       }

  //       dispatch(storieAction.setSwiped({ swiped: true }));
  //       dispatch(chefActions.onStoryBackClick());

  //       setTimeout(() => {
  //         dispatch(storieAction.setSwiped({ swiped: false }));
  //         dispatch(
  //           chefActions.onStoryClick({
  //             storyId: 0,
  //             storyImg: categories[chefId[0]][i - 1].img
  //               ? S3_MENU_THUMBNAIL_FOLDER + categories[chefId[0]][i - 1]?.img
  //               : "",
  //             dishes: categories[chefId[0]][i - 1].dishes,
  //             categories: categoriesWithClass,
  //             chefId: chefId[0],
  //             categoryName: categories[chefId[0]][i - 1].name,
  //           })
  //         );
  //       }, 800);
  //     } else {
  //       dispatch(chefActions.onStoryBackClick());
  //     }
  //   } catch (error) {
  //     dispatch(chefActions.onStoryBackClick());
  //   }
  // };

  // const stories = Array.from(
  //   dishImg.map((img, i) => {
  //     return {
  //       content: ({ action, isPaused }) => {
  //         return (
  //           <Story
  //             img={img}
  //             action={action}
  //             isPaused={isPaused}
  //             headers={headers[i]}
  //             name={props.name}
  //             categoryImg={props.headerImg}
  //             price={price[i]}
  //             chefId={chefId[i]}
  //           />
  //         );
  //       },
  //     };
  //   })
  // );

  const getStoriesLength = (catDishes, isActive) => {
    let count = 0;
    const dishImgs = catDishes.dishes.map((item) => item.stories[0]?.img);
    dishImgs.forEach((img, i) => {
      if(img) count++;
    })
    return count;
  };

  const getStories = (catDishes, isActive) => {
    try {
      // console.log(catDishes.img);
      const dishImgs = catDishes.dishes.map((item) => item.stories[0]?.img);
      const headers = catDishes.dishes.map((item) => item?.name);
      const price = catDishes.dishes.map((item) => item?.price);
      const chefId = catDishes.dishes.map((item) => item?.chefId);
      const description = catDishes.dishes.map((item) => item?.description);
      let storyContent = [];
      dishImgs.forEach((img, i) => {
        if(img){
          storyContent.push({
            content: ({ action, isPaused }) => {
              //PauseStories(action, isActive);
              if(!isActive){
                setTimeout(() => {
                  action('pause');
                }, 0);
              } else if(!isPaused){
                setTimeout(() => {
                  action('play');
                }, 0);
              }
              return (
                <Story
                  img={img}
                  action={action}
                  isPaused={isPaused}
                  isActive={isActive}
                  headers={headers[i]}
                  name={catDishes?.name}
                  categoryImg={catDishes.img ? S3_MENU_THUMBNAIL_FOLDER + catDishes.img : ""}
                  price={price[i]}
                  chefId={chefId[i]}
                  description={description[i]}
                />
              );
            },
          })
        }

      });
      storiesList = storyContent;
      return storyContent;
    } catch (error) {
      console.log("Error");
    }
  };

  return (
    <Swiper
        effect={"slide"}
        modules={[EffectFade]}
        className="mySwiper"
        onInit={(ev) => {
          set_my_swiper(ev)
        }}
        onSwiper={(s) => setSwiper(s)}
      >
        {categoriesWithClass.map((categoryWithClass, i) => {
          if (categoryWithClass.dishes[0].stories?.[0]) {
            return (
              <SwiperSlide key={i}>
                {({ isActive }) => (
                  <Stories
                    key={i}
                    isPaused={true}
                    //currentIndex={isActive ? 0 : 1}
                    stories={getStories(categoryWithClass, isActive)}
                    defaultInterval={5000}
                    width="100vw"
                    height="100vh"
                    progressContainerStyles={{ top: '2px' }}
                    onStoryStart={(s) => {

                      if (isActive) {
                        storySlideNumber = s;
                        // console.log('Story Start', storySlideNumber, s)
                      }
                    }}
                    onStoryEnd={(s) => {
                      if (isActive) {
                        storySlideNumber = s;
                        // console.log('Story end', s)
                      }
                      if(s === getStoriesLength(categoryWithClass, isActive)-1){
                        const canSwipe = swipeToNextSlide();
                        if (canSwipe) {
                          storySlideNumber = 0;
                          my_swiper.slideNext();
                        } else{
                          dispatch(chefActions.onStoryBackClick());
                        }
                      }
                    }}
                    // onAllStoriesEnd={(s) => {
                    //   console.log('Closed on end', s)
                    //   const canSwipe = swipeToNextSlide();
                    //   if (canSwipe) {
                    //     storySlideNumber = 0;
                    //     my_swiper.slideNext();
                    //   } else{
                    //     dispatch(chefActions.onStoryBackClick());
                    //   }
                    // }
                    // }
                  />
                )}
              </SwiperSlide>
            );
          }
        })}
    </Swiper>
    // <Swiper direction="vertical" effect={"slide"} modules={[EffectFade]}>
    //   <SwiperSlide>
    //   <Swiper
    //     effect={"slide"}
    //     modules={[EffectFade]}
    //     className="mySwiper"
    //     onInit={(ev) => {
    //       set_my_swiper(ev)
    //     }}
    //     onSwiper={(s) => setSwiper(s)}
    //   >
    //     {categoriesWithClass.map((categoryWithClass, i) => {
    //       if (categoryWithClass.dishes[0].stories?.[0]) {
    //         return (
    //           <SwiperSlide key={i}>
    //             {({ isActive }) => (
    //               <Stories
    //                 key={i}
    //                 isPaused={!isActive}
    //                 currentIndex={isActive ? 0 : 1}
    //                 stories={getStories(categoryWithClass)}
    //                 defaultInterval={3000}
    //                 width="100vw"
    //                 height="100vh"
    //                 progressContainerStyles={{ top: '2px' }}
    //                 onStoryStart={(s) => {
    //                   if (isActive) {
    //                     storySlideNumber = s;
    //                     // console.log('Story Start', storySlideNumber, s)
    //                   }
    //                 }}
    //                 onStoryEnd={(s) => {
    //                   if (isActive) {
    //                     storySlideNumber = s;
    //                     // console.log('Story end', s)
    //                   }
    //                 }}
    //                 onAllStoriesEnd={(s) => {
    //                   // console.log('Closed on end', s)
    //                   const canSwipe = swipeToNextSlide();
    //                   if (canSwipe) {
    //                     storySlideNumber = 0;
    //                     my_swiper.slideNext();
    //                   }
    //                 }
    //                 }
    //               />
    //             )}
    //           </SwiperSlide>
    //         );
    //       }
    //     })}
    //   </Swiper>
    //   </SwiperSlide>

    //   <SwiperSlide>
    //   <Swiper
    //     effect={"slide"}
    //     modules={[EffectFade]}
    //     className="mySwiper2"
    //     onInit={(ev) => {
    //       set_my_swiper(ev)
    //     }}
    //     onSwiper={(s) => setSwiper(s)}
    //   >
    //     {categoriesWithClass.map((categoryWithClass, i) => {
    //       if (categoryWithClass.dishes[1].stories?.[0]) {
    //         return (
    //           <SwiperSlide key={i}>
    //             {({ isActive }) => (
    //               <Stories
    //                 key={i}
    //                 isPaused={!isActive}
    //                 currentIndex={isActive ? 0 : 1}
    //                 stories={getStories(categoryWithClass)}
    //                 defaultInterval={3000}
    //                 width="100vw"
    //                 height="100vh"
    //                 progressContainerStyles={{ top: '2px' }}
    //                 onStoryStart={(s) => {
    //                   if (isActive) {
    //                     storySlideNumber = s;
    //                     // console.log('Story Start', storySlideNumber, s)
    //                   }
    //                 }}
    //                 onStoryEnd={(s) => {
    //                   if (isActive) {
    //                     storySlideNumber = s;
    //                     // console.log('Story end', s)
    //                   }
    //                 }}
    //                 onAllStoriesEnd={(s) => {
    //                   // console.log('Closed on end', s)
    //                   const canSwipe = swipeToNextSlide();
    //                   if (canSwipe) {
    //                     storySlideNumber = 0;
    //                     my_swiper.slideNext();
    //                   }
    //                 }
    //                 }
    //               />
    //             )}
    //           </SwiperSlide>
    //         );
    //       }
    //     })}
    //   </Swiper>
    //   </SwiperSlide>

    // </Swiper>

  );
};

export default DishStories;
