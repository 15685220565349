import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./chef-cards.css";
import { EVENING_BREAK_SHIFT_END_TIME, EVENING_BREAK_SHIFT_START_TIME, EVENING_SHIFT_END_TIME, EVENING_SHIFT_START_TIME, MORNING_BREAK_SHIFT_END_TIME, MORNING_BREAK_SHIFT_START_TIME, MORNING_SHIFT_END_TIME, MORNING_SHIFT_START_TIME, PREP_TIME, WEEK_OFF } from "../../store/constants";
import moment from "moment";
import { formatScheduleTime, getTodayDateValue } from "../../utils/timeHelper";

const ChefName = (props) => {
  
  const { isSwitchOff, chefWaitTime, showOnlyDot = false, schedules = [] } = props;
  const delivery_duration = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  );
  const isServiceable = useSelector((state) => state.auth.isServiceable);
  // const now = new Date().getHours();
  // const today = new Date().getDay();
  let delivery_Time = delivery_duration ? Math.round(delivery_duration / 60) : 10;
  // let isMorning = false;
  // let isAvailable = "away";
  // let offDay = false;
  // if (isServiceable) {
  //   if (props.leave) {
  //     isAvailable = "leave"
  //   } else if (props.breakShift) {
  //     if (now >= MORNING_BREAK_SHIFT_START_TIME && now < MORNING_BREAK_SHIFT_END_TIME) {
  //       isAvailable = "online";
  //     } else if (now >= EVENING_BREAK_SHIFT_START_TIME && now < EVENING_BREAK_SHIFT_END_TIME) {
  //       isAvailable = "online"
  //     }
  //   } else {
  //     if (now >= MORNING_SHIFT_START_TIME && now < MORNING_SHIFT_END_TIME) {
  //       if (props.shift) {
  //         isAvailable = "online"
  //       }
  //     } else if (now >= EVENING_SHIFT_START_TIME || now < EVENING_SHIFT_END_TIME) {
  //       if (!props.shift) {
  //         isAvailable = "online"
  //       }
  //     }
  //   }
  // } else {
  //   isAvailable = "unserviceable";
  // }
  // if (isSwitchOff) {
  //   isAvailable = "leave";
  // }

  // if (now < MORNING_BREAK_SHIFT_END_TIME) {
  //   isMorning = true;
  // }

  // if ((today == WEEK_OFF && now > EVENING_SHIFT_END_TIME) || (today == (WEEK_OFF + 1) && now < EVENING_SHIFT_END_TIME)) {
  //   offDay = true;
  // }
  const chefWaitTimeValue = Math.max(PREP_TIME, chefWaitTime || 0);
  const todayChefSchedule = getTodayDateValue(schedules);
  

  const getStatusLabel = () => {
    if (!isServiceable) {
      return "Pre-bookings only"
    } else if (isSwitchOff) {
      return "Coming soon"
    } else if (!todayChefSchedule?.isAvailable) {
      const findNextDayIndex = schedules.findIndex((s) => s._id === todayChefSchedule._id);
      if(findNextDayIndex === (schedules.length - 1)){
      const nextDayInfo = schedules[0]
      return `Opens tomorrow at ${nextDayInfo?.startTime}` 
      }else{
      const nextDayInfo = schedules[findNextDayIndex + 1]
      return `Opens tomorrow at ${nextDayInfo?.startTime}`
      }
    } else if (todayChefSchedule?.online) {
      if (delivery_duration) {
        return `Delivery in ${delivery_Time + chefWaitTimeValue} mins`;
      } else {
        return "Open now";
      }
    } else if (todayChefSchedule?.isAvailable) {
      return `Opens at ${moment(todayChefSchedule?.startTime, 'h:mm A').format('h a')}`
    }
  }

  const getStatusClassname = () => {
    if (!isServiceable) {
      return "chef-non-serviciable";
    } else if (todayChefSchedule?.online) {
      return "chef-online";
    } else {
      return "chef-away";
    }
  };

  if (showOnlyDot) {
    return <div style={{width:12}} className="chef-status-indicator">
      <div
        className={getStatusClassname()}
      />
    </div>
  }

  return (
    <>
      <div className="chef-detail">
        <h4 className="chef-name">Chef {props.name}</h4>
        <div style={{width:"134%", gap:"2px"}} className="chef-status-indicator">
          {/* <div
          className={`
          ${
            offDay ? "chef-away"
            : isAvailable === "online"
              ? "chef-online"
              : isAvailable === "away"
              ? "chef-away"
              : "chef-non-serviciable"
          }
        `}
        />
        <p>
          {offDay ? "Weekly off"
          : isAvailable === "online"
            ? delivery_duration ? `Delivery in ${Math.max(PREP_TIME, chefWaitTime) + delivery_Time} mins` : "Open now"
            : isAvailable === "away"
            ? `Opens at ${props.breakShift ? isMorning ? "11am" : "7pm"  : props.shift ? "11am" : "7pm"}`
            : isAvailable === "leave"
            ? `Away on leave`
            : "Pre-bookings only"}
        </p> */}

          <div
            className={getStatusClassname()}
          />
          <p>
            <button className="chef-status-button">
              {getStatusLabel()}
            </button>
            {/* {offDay ? "Weekly off"
            : isAvailable === "online"
              ? delivery_duration ? `Delivery in ${PREP_TIME + delivery_Time} mins` : "Open now"
              : isAvailable === "away"
                ? `Opens at ${props.breakShift ? isMorning ? "11am" : "7pm" : props.shift ? "11am" : "7pm"}`
                : isAvailable === "leave"
                  ? `Away on leave`
                  : "Pre-bookings only"} */}
          </p>
        </div>
      </div>
     
    </>
  );
};

export default ChefName;
