import React, { useState } from "react";
import moment from 'moment';
import "./ScheduleOrderButton.css";
import ClockSVGComponent from "../jsxsvg/ClockSVGComponent";
import DropDownArrowSVGComponent from "../jsxsvg/DropDownArrowSVGComponent";
import { useSelector } from "react-redux";
import TimeSlotBottonSlider from "../timeSlotBottonSlider";

const ScheduleOrderButton = ({ customButton, chefsLatestTime }) => {
  const {
    isScheduleNow,
    scheduleTime
  } = useSelector(({ cart }) => cart);
  const [showModal, setShowModal] = useState(false);

  const [scheduleDate, setScheduleDate] = useState({
    date: scheduleTime?.date || moment(),
    time: scheduleTime?.time || "ORDER_NOW",
  });
  const onScheduleClick = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const dateValue = moment(scheduleDate.date).format("MMM D");
  const timeValue = moment(scheduleDate.time).format("h:mm a");


  return (
    <>
      {customButton ? <button onClick={onScheduleClick} className="scheduleOrderButton" style={{ background: "transparent", color: "#7d9a6f", fontWeight: "bold", fontSize: 14 }} > {customButton} </button> : <button onClick={onScheduleClick} className="scheduleOrderButton">
        <div className="ScheduleOrderButtonClock">
          <ClockSVGComponent />
        </div>
        {`${isScheduleNow ? "Order Now" : `${dateValue},${timeValue}`}`}
        <div className="ScheduleOrderButtonDropDown">
          <DropDownArrowSVGComponent />
        </div>
      </button>}

      <TimeSlotBottonSlider isOpen={showModal} chefsLatestTime={chefsLatestTime} setOpen={setShowModal} setScheduleDate={setScheduleDate}
        scheduleDate={scheduleDate} />
    </>
  );
};

export default ScheduleOrderButton;
