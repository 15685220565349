import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { loginActions } from "../../store/loginSlice";
import { dateFormatter, timeFormatter } from "../util/DateTimeFormatter";
import "./order-history.css";
import { getOrderStatusLabel } from "../../utils/helper";

const GST_RATE = 5;

const OrderHistory = ({ data, isSchedule }) => {
  const dispatch = useDispatch();
  const {
    cartData,
    totalCost,
  } = useSelector(({ cart }) => cart);
  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onOrderHistoryClick = () => {
    dispatch(loginActions.setSingleOrderHistoryDetails(data));
    dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }))
  }

  const { createdAt, status, reviewType, reviewText } = data;
  const reviewTypeString =
    reviewType?.charAt(0) + reviewType?.slice(1).toLowerCase();
  let orderedDate = new Date(createdAt);
  let totalPrice = 0;
  let orderedDishes = "";
  let chefDetails = "";
  data.bookedDishes.forEach((dishes, i) => {
    dishes.isAddedOnDiscount || data.preOrdered
      ? (totalPrice += dishes.dealPrice)
      : (totalPrice += dishes.price);
    if (data.bookedDishes.length == 1 || i == data.bookedDishes.length - 1) {
      if (dishes.dish) {
        orderedDishes += dishes.dish.name;
        if (!chefDetails.includes(dishes.dish.chefName)) {
          chefDetails += "Chef " + dishes.dish.chefName + ".";
        }
      }
    } else {
      if (dishes.dish) {
        orderedDishes += dishes.dish.name + ", ";
        if (!chefDetails.includes(dishes.dish.chefName)) {
          chefDetails += "Chef " + dishes.dish.chefName + ", ";
        }
      }
    }
  });

  const onReviewLinkClick = () => {
    if(reviewType) return
    dispatch(
      loginActions.setOrderReviewWriteInfo({
        orderReviewWriteInfo: { ...data, chefName: chefDetails },
      })
    );
    dispatch(bottomBarActions.loadTab({ tabName: "orderReviewWrite" }));
  };

  const getReviewColor = () => {
    switch (reviewType) {
      case "YES":
        return "green";
      case "OTHER":
        return "#dd9d4a";
      case "NO":
        return "red";
    }
  };


  const billingAmount = Math.round(data.totalCost + data.gstFee + data.packagingFee)
 
  return (
    <div className="order-history_container" >
      <div style={{ width: "100%" }} className="bd-top-section">
        <div
          style={{ width: "100%", marginRight: 0 }}
          className="order-history_left"
        >
          <div className="order-history_left_header">
            <h1>{chefDetails}</h1>{" "}
          </div>
          <p>{data.address?.location?.locationName}</p>
          <p>
            {dateFormatter.format(orderedDate)},{" "}
            {timeFormatter.format(orderedDate)}
          </p>
        </div>
        <div className="order-history_right">
          <span className="order-history_left_header_status">
            {getOrderStatusLabel(status)}
          </span>
          {(data.billingAmount || billingAmount) && data.orderType !== "pre-launch" ? <button onClick={onOrderHistoryClick} className="order-history_viewbutton">
            <span>View Order</span>
          </button> : <div></div>}
          {data.orderType == "pre-launch" && (
            <div
              className="order-history_schedule"
              onClick={() => {
                switchTab("addressForm");
                dispatch(loginActions.setSelectedOrder(data));
              }}
            >
              <img src="images/time-period.svg" />
              <button type="button" className="order-history_btn-schedule">
                Schedule
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="bd-bottom-section">
        <p>{orderedDishes}</p>
        <p>{!!(data.billingAmount || billingAmount) && `₹${(data.billingAmount || billingAmount)}`}</p>
      </div>
      <div className="bd-bottom-section">
        <div className="review-label-link" onClick={onReviewLinkClick}>
          were you over all satisfied by this Order?
        </div>
        <div
          style={{ color: getReviewColor() }}
          className="review-type-label-link"
        >
          {reviewType && `You said ${reviewTypeString}`}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
