import React, { useEffect, useState } from "react";
import BottomBar from "../BottomBar";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { useSelector, useDispatch } from "react-redux";
import "../dishBook/dishBook.css";
import "./chefOrderReviewWrite.css";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "../menu/browse-menu-button.css";
import { setBookingData, updateOrderAction } from "../../store/loginActions";

const ChefOrderReviewWrite = () => {
  const [reviewState, setReviewState] = useState({ type: "", text: "" });
  const orderReviewWriteInfo = useSelector(
    ({ auth }) => auth.user.orderReviewWriteInfo
  );
  const { chefName } = orderReviewWriteInfo || {};
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBookingData(user.userid));
  }, [dispatch, user.userid]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("dishBook");
  };
console.log('orderReviewWriteInfo', orderReviewWriteInfo)
  const onChangeReviewState = (key, value) => {
    setReviewState((prev) => ({ ...prev, [key]: value }));
  };

  const onReviewSubmit = () => {
    dispatch(updateOrderAction(orderReviewWriteInfo._id, { reviewType: reviewState.type, reviewText: reviewState.text }))
      .then(() => {
        onBackClick()
      })
      .catch((error) => {
        // Handle the error here.
      });
  };

  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name={chefName}
            cuisine=""
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <section className="section-food-accordian">
          <div className="food-accordian">
            <h1 className="order-history_container_header">
              Were you over all satisfied with the Order?
            </h1>
            <div className="review-box-wrapper">
              <button
                onClick={() => onChangeReviewState("type", "YES")}
                className={`review-positive-button ${
                  reviewState.type === "YES" && `review-positive-button-active`
                }`}
              >
                Yes
              </button>
              <button
                onClick={() => onChangeReviewState("type", "OTHER")}
                className={`review-custom-label-button ${
                  reviewState.type === "OTHER" && `review-custom-label-active`
                }`}
              >
                Somewhat
              </button>
              <button
                onClick={() => onChangeReviewState("type", "NO")}
                className={`review--nonpositive-button ${
                  reviewState.type === "NO" && `review--nonpositive-button-active`
                }`}
              >
                No
              </button>
            </div>
            <textarea
              placeholder="Tell us more..."
              disabled={!reviewState.type}
              className="review-food-textarea"
              onChange={(e) => {
                onChangeReviewState("text", e.target.value);
              }}
            ></textarea>
            <div className="post-review-button-wrapper">
              <button onClick={onReviewSubmit} className="post-review-button">Submit</button>
            </div>
          </div>
        </section>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default ChefOrderReviewWrite;
