export const getLatLngArray = (data) => {
  const locationSteps = data.map((direction) => ({
    lat: direction.start_location.lat,
    lng: direction.start_location.lng,
  }));
  const lastIndexValue = data[data.length - 1];
  locationSteps.push({
    lat: lastIndexValue.end_location.lat,
    lng: lastIndexValue.end_location.lng,
  });
  return locationSteps;
};

export const getNextDirection = (response) =>{
  const distance = response.data.data.routes[0].legs[0].distance.value
  console.log('response', )
  return distance
}